import * as React from "react"
import { Link } from "gatsby"

export interface ButtonProps {
  anchor: string
  label: string
  primary?: boolean
}

const Button = (props: ButtonProps) => (
  <Link
    className={`inline-block px-8 py-3 text-white transform duration-200 rounded-lg ${
      props.primary
        ? "bg-blue-600 hover:bg-blue-700"
        : "bg-black hover:bg-gray-900"
    }`}
    to={props.anchor}
  >
    {props.label}
  </Link>
)

export default Button
