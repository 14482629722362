import * as React from "react"

import { links } from "../common/constants"
import Button from "../components/button"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Thank you!" />

    <section className="relative py-24 overflow-hidden">
      <div className="container px-4 mx-auto relative">
        <div className="max-w-4xl mx-auto text-center relative z-10">
          <h3 className="text-4xl lg:text-5xl font-heading mb-12 text-black">
            Grazie per esserti iscritto alla nostra newsletter!
          </h3>
          <p className="mb-10 text-lg">
            Non ti invieremo spam. Se desideri annullare l'iscrizione, invia
            un'e-mail a{" "}
            <a
              href="mailto:info@gruppomontebello.it"
              className="text-black underline hover:text-gray-900"
            >
              info@gruppomontebello.it
            </a>
            .
          </p>
          <Button anchor={links.home} label="Torna indietro" />
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
